<template>
  <component
    :is="componentName(element, components)"
    v-for="element in page.elements"
    :key="element.id"
    :element="element"
  />
</template>

<script setup lang="ts">
  const { componentName } = useAlchemy()

  const props = defineProps<{
    product: SolidusProduct
    page: AlchemyPage
  }>()

  useHead(usePageInfo(props.page))

  const components = {
    page_header: defineAsyncComponent(
      () => import("~/alchemy/elements/page_header.vue"),
    ),
    material_supply_calculator: defineAsyncComponent(
      () => import("~/alchemy/elements/material_supply_calculator.vue"),
    ),
  }
</script>

<style scoped lang="scss">
  .dev-note {
    padding: $space-m;
    color: $yellow-text;
    border: 1px solid $yellow-shape;
    background: $yellow-tint;
  }
</style>
